import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
  } from '@mui/material';
  import { styled } from '@mui/material/styles';
  import  { tableCellClasses } from '@mui/material/TableCell';
import { useEffect,useState} from 'react';
import { toast } from 'react-toastify';
import { MonthlyDailyReport } from '../../Services/Dashboard';
import moment from 'moment/moment';
import SpinnerModal from '../../Spinner/SpinnerModal';

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5bc5d0',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: '#ffffff',
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nStyledTableCell-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child StyledTableCell, &:last-child StyledTableCell': {
      border: 0,
    },
  }));






const DailyReports = () =>{
    const [isLoading, setIsLoading] = useState(false);
    const[DailyReports,DailyReport] = useState([]);




  //     --------- Monthly daily report----------
const GetMonthlyDaily = async () =>{
    try{
      setIsLoading(true);
      const LabID = sessionStorage.getItem('LabID');
      const response = await MonthlyDailyReport(LabID);
      DailyReport(response.dailyReports);
        
    }catch(error){
      toast.error("Unabled to get States data",error)
    }finally{
      setIsLoading(false);
    }
  }

  useEffect(()=>{
    GetMonthlyDaily();
  },[])
//-------------------Pagination
 const [currentPage, setCurrentPage] = useState(0); // Start from page 1
const itemsPerPage = 10;
const indexOfLastItem = (currentPage + 1) * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const limitedData = DailyReports.slice(indexOfFirstItem, indexOfLastItem);
const handlePageChange = (event, newPage) => {
setCurrentPage(newPage);
};
    return(
        <>
         {isLoading && <SpinnerModal isLoading={isLoading} />}
          <div className="container-fluid">
              <div>
                <h3>Daily Reports</h3>
              </div>
              
               <div>
               <TableContainer className='PatientListTable' sx={{ maxHeight: '80vh',minHeight:'80vh' }}>
                <Table  stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <StyledTableRow>
                              <StyledTableCell className='DTC1'>Date</StyledTableCell>
                                <StyledTableCell className='DTC1'>Day</StyledTableCell>
                                <StyledTableCell className='DTC1'>Tests</StyledTableCell>
                                <StyledTableCell className='DTC1'>Sale</StyledTableCell>
                                <StyledTableCell className='DTC1'>Discount</StyledTableCell>
                                <StyledTableCell className='DTC1'>Recevied Ammount</StyledTableCell>
                                <StyledTableCell className='DTC1'>Due ammount</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>  
                    {limitedData.map((data, index) => (
                         <StyledTableRow  key={index}>
                                <StyledTableCell className='DTC'>{moment(data.recordDate).format('D MMM YYYY')} </StyledTableCell>
                                <StyledTableCell className='DTC'>{data.dayName} </StyledTableCell>
                                <StyledTableCell className='DTC'>{data.totalTest}</StyledTableCell>
                                <StyledTableCell className='DTC'>{data.totalGrandTotal}</StyledTableCell>
                                <StyledTableCell className='DTC'>{data.totalDiscount}</StyledTableCell>
                                <StyledTableCell className='DTC'>{data.receivedAmount}</StyledTableCell>
                                <StyledTableCell className='DTC'>{data.dueAmount}</StyledTableCell>
                            </StyledTableRow>
                                ))}
                    </TableBody>
                  
                </Table>
                <hr></hr>
                <TablePagination
                       component="div"
                       count={DailyReports.length}
                       page={currentPage}
                       onPageChange={handlePageChange}
                       rowsPerPage={itemsPerPage}
                       rowsPerPageOptions={[itemsPerPage]}
                   />
            </TableContainer>
             
               </div>
          </div>
        </>
    )
}

export default  DailyReports