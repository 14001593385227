import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { GetSinglepatient } from '../Services/PatientServicces';
import { GetPrescription } from '../Services/TestServices';
import 'bootstrap/dist/css/bootstrap.min.css';
import Female from '../assets/female-Pofile.jpg';
import Male from '../assets/Male.jpg';
import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,Box, Typography, Dialog, DialogTitle, DialogContent, Slide, Checkbox, TextField, Button } from '@mui/material';
import SpinnerModal from '../Spinner/SpinnerModal';
import moment from 'moment';
import { Reports,Generate_Reports,GetDoctorInfo,GetFontStyles,GetStaticResult } from '../Services/TestServices';
import PrintReports from './PrintReports';
import { useReactToPrint } from 'react-to-print';
import { useApi } from '../ContextApi/ContextApi';
import { toast } from 'react-toastify';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import '../UI/PatientProfile.css';




const PatientProfile = () => {
  const[info,SetInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ProfileData, setProfileData] = useState({});
  const [PrescriptionData, setPrescriptionData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const { isReportGenerate, setReport_generation_SubmissionStatus } = useApi();
  const [selectedTests, setSelectedTests] = useState([]);
  const[Fonts,SetFonts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const componentRef = useRef();
  const { id } = useParams();
  const inputRefs = useRef([]);
  const [Comment,setComment] = useState('');
  const [QrCodeURl,SetQRCodeUrl] = useState([]);
  const [commentVisibility, setCommentVisibility] = useState({});
  const [STATICRESULTS,SETRESULTS] = useState([]);
  sessionStorage.setItem('PatientID', id);

  const icon = ProfileData?.[0]?.sex === "male" ? <ManIcon className='genderIcon'/> : <WomanIcon className='genderIcon'/>;
  const sexLabel = ProfileData?.[0]?.sex;
//---------------------------------getting profile Data----------
useEffect(() => {
  GetprofileData();
}, [id]);


  const GetprofileData = async () => {
    setIsLoading(true);
    try {
      const LabID = sessionStorage.getItem('LabID');
      const data = await GetSinglepatient(id, LabID);
      setProfileData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
//----------------------------print functionality---------------------
  useEffect(() => {
    if (selectedTests.length > 0) {
      PrintReport();
    }
  }, [selectedTests]);
//------------------------print call -------------------------------
  const print = useReactToPrint({
    content: () => componentRef.current,
  });
//--------------------------------Getting Print Report data-----------------
const PrintReport = async () => {
  setIsLoading(true);
  try {
    const patientID = sessionStorage.getItem('PatientID');
    const LabID = sessionStorage.getItem('LabID');
    const IDs = selectedTests.map(x => x.labOrderID).join('&SelectedIDs=');

const Url = `https://securelab.api.sehhasolutions.com/api/Home/QRCodeData?PatientID=${patientID}&LabID=${LabID}&SelectedIDs=${IDs}`;
SetQRCodeUrl(Url);
    const data = await Reports(patientID, LabID,selectedTests);
    setReportData(data);
  } catch (error) {
    console.error('Failed to fetch reports:', error);
  } finally {
    setIsLoading(false);
  }
};

//-------------------END-----------------
  
//------------------calling all functions which should be called in render------------
  useEffect(() => {
    GetPresciptionData();
    setReport_generation_SubmissionStatus(false);
  }, [isReportGenerate]);
//-------------------------------Getting Prescription Data-------------------
  const GetPresciptionData = async () => {
    const LabID = sessionStorage.getItem('LabID');
    const response = await GetPrescription(id, LabID);
    setPrescriptionData(response);
  };

  //---------------------------handle checkbox-----------------

  const handleCheckboxChange = (testID, labOrderID) => {
    const LabID = sessionStorage.getItem('LabID');
     const patientID = sessionStorage.getItem('PatientID');
     const newTest = { LabID, patientID, testID ,labOrderID};
    setSelectedTests(prevSelectedTests => {
      const testExists = prevSelectedTests.some(test => test.testID === testID && test.labOrderID === labOrderID);
  
      if (testExists) {
        return prevSelectedTests.filter(test => !(test.testID === testID && test.labOrderID === labOrderID));
      } else {
        return  [...prevSelectedTests, newTest]
      }
    });
  };
  
//----------------------END-------------------------------
//------------------------Handle select All test--------------


const handleSelectAllChange = (event) => {
  const checked = event.target.checked;
  setSelectAll(checked);

  if (checked) {
    const allTests = PrescriptionData.filter(test => test.testResult).map(test => ({
      LabID: sessionStorage.getItem('LabID'),
      patientID: sessionStorage.getItem('PatientID'),
      testID: test.testID,
      labOrderID: test.labOrderid
    }));
    setSelectedTests(allTests);
  } else {
    setSelectedTests([]);
  }
};

//---------------------------END----------------------
//======================= Group data by date and time========
const groupByDate = (data) => {
  return data.reduce((acc, item) => {
    const date = moment(item.createdAt).format('YYYY-MM-DD');
    if (!acc[date]) {
      acc[date] = [];
    }
    const isDuplicate = acc[date].some(existingItem => existingItem.labOrderid === item.labOrderid);
    if(!isDuplicate){
      acc[date].push(item);
    }
    return acc;
  }, {});
};
const groupedData = groupByDate(PrescriptionData);
//=============END=====================================

  //--------------------------handle result change and keydown and  save----------------------
  const handleKeyDown = (event, testID,LabOrderID,groupIndex, index) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if inside a form
      handleSave(testID,LabOrderID,groupIndex, index);
    }
  };
  
  const handleInputChange = (testID, LabOrderID,value ) => {
    
    setPrescriptionData((prevPrescriptionData) =>
      prevPrescriptionData.map(test =>
        (test.testID === testID && test.labOrderid === LabOrderID )? { ...test, labOrderID_In_GR: LabOrderID, testResult: value } : test
      )
    );
  };
  
  const handleSave = async (testID,LabOrderID,groupIndex,index) => {
    setIsLoading(true);
    try {
      
      const testToSave = PrescriptionData.find(test => test.testID === testID && test.labOrderid === LabOrderID);
      const LabID = sessionStorage.getItem('LabID');
      const patientID = sessionStorage.getItem('PatientID');

      // Include patient ID and laboratory ID when saving data
        const savedData = {
          ...testToSave,
          LabID,
          patientID,
          reportID: testToSave.reportID || 0,
          Comment: testToSave.comment
        };
     if(savedData.testResult == '' || savedData.testResult == null){
          toast.error("Result required")
     }else{
      const Response = await Generate_Reports(savedData);
      if (Response) {
        toast.success("Report Generated Successfully");
        setReport_generation_SubmissionStatus(true);
        
        if (inputRefs.current[`${groupIndex}-${index + 1}`]) {
          inputRefs.current[`${groupIndex}-${index + 1}`].focus();
        } else if (inputRefs.current[`${groupIndex + 1}-0`]) {
          inputRefs.current[`${groupIndex + 1}-0`].focus();
        }
    }
  }
    } catch (error) {
      toast.error("Error occurred while saving data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  //---------------------------End-----------------------

 


  //----------------------------------modal open ---------------
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  //----------------------tab change ---------------------
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
//-----------------END-------------
 //---------------Formate normal range and unit value -- function 
 const formatTextWithLineBreaks = (text) => {
  return text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < text.split('\n').length - 1 && <br />}
    </React.Fragment>
  ));
};

//---------------END ----------------
//---------------Get Doctor Info-------------
const DoctorInfo = async () =>{
  setIsLoading(true)
  try{
   
    const LabID = sessionStorage.getItem('LabID');
    const response  =await GetDoctorInfo(LabID);
    if(response != null){
      SetInfo(response)
    }
  }catch(error){
    toast.error(error)
  }finally{
    setIsLoading(false)
  }
}
//----------------End--------
//--------------Get Font Styles-----------
const Fontstyle = async () =>{
  setIsLoading(true)
  try{
   
    const LabID = sessionStorage.getItem('LabID');
    const response  =await GetFontStyles(LabID);
    if(response != null){
      SetFonts(response)
    }
  }catch(error){
    toast.error(error)
  }finally{
    setIsLoading(false)
  }
}
useEffect(()=>{
  DoctorInfo();
  Fontstyle();
},[])
//------------End-------------------
//-------------Add-Comment-------------
const OnchangeComment = (TestID,OrderID,Value) =>{
  setPrescriptionData((prevPrescriptionData) =>
    prevPrescriptionData.map(test =>
      (test.testID === TestID && test.labOrderid === OrderID )? { ...test, comment: Value } : test
    )
  );
}
//-----------------END---------
//-------comment show and hide 
const toggleCommentVisibility = (testID, labOrderID) => {
  setCommentVisibility(prev => ({
    ...prev,
    [`${testID}-${labOrderID}`]: !prev[`${testID}-${labOrderID}`]
  }));
};
//--------end-----------
const STATICRESULT = async () =>{
  try{
    const LabID = sessionStorage.getItem('LabID');
    const response = await GetStaticResult(LabID);
    SETRESULTS(response);
  }
  catch(error){
     console.log("Error getting Static Result",error)
  }
}
useEffect(()=>{
  STATICRESULT();
},[])
  return (
    <>
      {isLoading && <SpinnerModal isLoading={isLoading} />}
      <div style={{ display: 'none' }}>
        <PrintReports reportData={reportData} DRInfos={info} Fonts={Fonts} QrCodeURL={QrCodeURl} ref={componentRef} />
      </div>
      <div style={{padding:'0px 30px'}}>
        <div className='row p-3 Profile-header'>
            <div  className='col-sm-1 Pics-div'>
               {ProfileData?.[0]?.sex === "male" ? (<img className='img-profile' src={Male} alt="Male Profile" />) : (<img className='img-profile' src={Female} alt="Female Profile" />)}
            </div> 
              <div className='col-sm-10' style={{marginLeft:'8px'}}>
                <div>
                   <h5 className='commont-Font-Bold'>{ProfileData?.[0]?.patientName.toUpperCase()}</h5>
                </div>
                <div className='d-flex'>
                  <div className='col-sm-4'>
                      <div className='d-flex common-FontSize-small'>
                        <span>{icon}{sexLabel}</span>
                        <span style={{marginLeft:'10px'}}><CalendarMonthIcon className='ProfileIcon'/> {ProfileData?.[0]?.age} Year</span>
                        <span style={{marginLeft:'10px'}}><ContactPhoneIcon className='ProfileIcon'/> {ProfileData?.[0]?.contactNumber}</span>
                      </div>
                      <span className='common-FontSize-small'><LocationOnIcon  className='ProfileIcon'/> {ProfileData?.[0]?.address}</span> 
                  </div>
                  <div className='col-sm-3' style={{marginLeft:'10px'}}>
                    <span className='common-FontSize-small'>MR No: {ProfileData?.[0]?.patientID}-{sessionStorage.getItem('LabID')}</span><br></br>
                    <span className='common-FontSize-small'>Preffered By: {ProfileData?.[0]?.referredBy}</span><br></br>
                    <span className='common-FontSize-small'>Registration Date: {moment(ProfileData?.[0]?.created_at).format('YYYY-MM-DD')}</span>
                  </div>
                  <div className='col-sm-4'> 
                        
                  </div>
                </div>
                
                   
           </div>
        </div>
      </div>
      <div  style={{padding:'0px 30px'}}>
      <div className='row pt-3 common-FontSize-small'>
          <div style={{textAlign:'end',marginBottom:'3px'}}>
               <button disabled={!selectedTests.length > 0} className='printButton'  onClick={print} >
                 <PrintRoundedIcon /> Print
                </button>
          </div>
           {Object.entries(groupedData).map(([date, tests], groupIndex) => (
            <div className='col-sm-12 Test-Container' >
            <React.Fragment key={date}>
              <Box className="d-flex justify-content-start" sx={{ padding: '5px', alignContent: 'center' }}>
                <div className='common-FontSize-small'>
                  {moment(date).format('MMMM Do YYYY')}
                </div>
              </Box>
               <div className='row Table-Head' style={{background:'#5bc5d0'}}>
                   <div className='col-sm-2'>
                    {groupIndex === 0 && (
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                            sx={{ color: 'white',padding:'0px', '&.Mui-checked': { color: 'white', padding:'0px'}, }}
                          />
                        )}  
                   </div>
                   <div className='col-sm-2'>
                     <span className='Presc_header'>Test</span>
                   </div>
                   <div className='col-sm-2'>
                     <span className='Presc_header'>Normal Range</span>
                   </div>
                   <div className='col-sm-3'>
                      <span className='Presc_header'>Result</span>
                   </div>
                   <div className='col-sm-3'>
                      <span className='Presc_header'>ACTIONs</span>
                  </div>
               </div>
               <div className='Table-body'>
               {tests.map((testItem, index) => (
                      <React.Fragment key={testItem.testID}>
                        <div className='row T-body-row'>
                          <div className='col-sm-2'>
                          <Checkbox
                              disabled={!testItem.testResult}
                              sx={{ color: '#5bc5d0', '&.Mui-checked': { color: '#5bc5d0' }, padding: '0px'}}
                              checked={selectedTests.some(test => test.testID === testItem.testID && test.labOrderID === testItem.labOrderid)}
                              onChange={() => handleCheckboxChange(testItem.testID , testItem.labOrderid)}
                            />
                          </div>
                          <div className='col-sm-2'>
                            <span>{testItem.testName}</span>
                          </div>
                          <div className='col-sm-2'>
                              <span className='Presc_header'>{formatTextWithLineBreaks(testItem.normalRange)}</span>
                          </div>
                          <div className='col-sm-3'>
                              <input
                                  required
                                  className='result-Textbox'
                                  value={testItem.testResult || ''}
                                  onChange={(e) => handleInputChange(testItem.testID, testItem.labOrderid, e.target.value)}
                                  onKeyDown={(e) => handleKeyDown(e, testItem.testID, testItem.labOrderid, groupIndex, index)}
                                  ref={(el) => (inputRefs.current[`${groupIndex}-${index}`] = el)}
                                  list="Suggestion" 
                                  
                                />
                                <datalist id="Suggestion">
                                  <option value="Nill" />
                                  <option value="Positive" />
                                  <option value="Negative" />
                                  {STATICRESULTS.map((RS, index) => (
                                    <option key={index} value={RS.sResult} />
                                  ))}
                                </datalist>
                          </div>
                          <div className='col-sm-3'>
                              {testItem.reportID ? (
                                  <button
                                    disabled={!testItem.testResult}
                                    className='Result-Updatebutton'
                                    size="small"
                                    onClick={() => handleSave(testItem.testID, testItem.labOrderid, groupIndex, index)}
                                  >
                                    update
                                  </button>
                                ) : (
                                  <button
                                    disabled={!testItem.testResult}
                                    className='Result-Savebutton'
                                    size="small"
                                    onClick={() => handleSave(testItem.testID, testItem.labOrderid, groupIndex, index)}
                                  >
                                    save
                                  </button>
                                )}
                              <button  type='button'  className='Comment-button' onClick={() => toggleCommentVisibility(testItem.testID, testItem.labOrderid)}>Comment</button>
                          </div>
                         
                        </div>
                        {commentVisibility[`${testItem.testID}-${testItem.labOrderid}`] && (
                          <div className='row'>
                            <div colSpan={5} className='comment-section'>
                              <textarea
                                value={testItem.comment}
                                onChange={(e) => OnchangeComment(testItem.testID, testItem.labOrderid, e.target.value)}
                                className="form-control form-control common-Input"
                                placeholder="Add comment here"
                              />
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
               </div>
             
            </React.Fragment>
         </div>
          ))}
         
         </div> 
      </div> 
         
 
    </>
  );
};

export default PatientProfile;
