import axios  from "axios";

// const baseURL = 'https://localhost:44397/api/'; //local
const baseURL = 'https://securelab.api.sehhasolutions.com/api/'; //prod


const apiServices = axios.create({
    baseURL,
})
export  const GetDashboard =async (data) =>{
    const token = sessionStorage.getItem('token');
    try{
        const response =  await apiServices.get(`Home/Dashboard?Labid=${data}`,{
                headers: {
                    'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                }
            });
        return response.data;
    }
    catch(error){
        throw(error);
    }
} 
export  const Monthly_Lab_Data =async (data) =>{
    const token = sessionStorage.getItem('token');
   
    try{
        const response =  await apiServices.get(`Home/Monthly_Lab_Data?Labid=${data.LabID}&StartDate=${data.StartDate}&EndDate=${data.EndDate}`,{
            headers: {
                'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
            }
        });
        return response.data;
    }
    catch(error){
        throw(error);
    }
}
export  const MonthlyDailyReport =async (LabID) =>{
    const token = sessionStorage.getItem('token');
    try{
        const response =  await apiServices.get(`Home/GetDashboardMOnthlyDaily?LabiD=${LabID}`,{
            headers: {
                'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
            }
        });
        return response.data;
    }
    catch(error){
        throw(error);
    }
} 