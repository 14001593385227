import react, { useState } from 'react'
import {Card,Box,TextField,MenuItem} from '@mui/material'
import '../UI/Registration.css'
import { LabandUserRegistration } from '../Services/Authservices'
import SpinnerModal from '../Spinner/SpinnerModal'
import { toast } from 'react-toastify'


const AccountRegistration = () =>{
    const [isLoading, setIsLoading] = useState(false); 
    const[IsError,setIserror] = useState(false);
   const[formData,setFormData] = useState({
    LaboratoryName:'',
    LaboratoryContactNumber:'',
    LaboratoryEmail:'',
    LaboratoryAddress:'',
    FirstName:'',
    LastName:'',
    Email:'',
    PhoneNumber:'',
    Password:'',
    user_Role:'',
   })

   const handleChange = (e) =>{
    const {name ,value} = e.target; 
    setFormData({
        ...formData,
        [name]:value
    })
   }
   const submit =async (e)  =>{
    e.preventDefault();
    setIsLoading(true)
    try{
       const response  = await LabandUserRegistration(formData)
       if(response){
         toast.success("Account created successfully")
       }
    }
    catch(error){
        console.log("Getting error while register the Lab")
       
    }
    finally{
        setIsLoading(false)
    }
   }

   const Role = [
      {
        value: 'Admin',
        label: 'Admin',
      },
    ];
    return(
        <>
         {isLoading && <SpinnerModal isLoading={true} />}
         <div className='container'>
            <Card sx={{padding:'2rem',height:'80%',boxShadow:" rgba(0, 0, 0, 0.2) 0px 18px 50px -10px",borderRadius:'5px',top:'5rem',position:'relative'}}>
                <h5>Registration Form</h5>
                <form onSubmit={submit}>
                <Box>
                <div className='row'>
                        <h6>Lab Information</h6>
                    <div className='col-4 mt-2'>
                    <TextField
                          inputProps={{ className: 'Registeration_Input' }}
                          InputLabelProps={{className:'AddPatient_Label' }}
                          label='Lab Name'
                          className=''
                          id='LaboratoryName'
                          name='LaboratoryName'
                          size='small'
                          onChange={handleChange}
                          required
                       />
                    </div>
                    <div className='col-4 mt-2'>
                    <TextField
                         inputProps={{ className: 'Registeration_Input' }}
                         InputLabelProps={{className:'AddPatient_Label' }}
                          label='Contact number'
                          className=''
                          id='LaboratoryContactNumber'
                          name='LaboratoryContactNumber'
                          size='small'
                          onChange={handleChange}
                          required
                       />
                    </div>
                    <div className='col-4 mt-2'>
                    <TextField
                         inputProps={{ className: 'Registeration_Input' }}
                         InputLabelProps={{className:'AddPatient_Label' }}
                          label='Email'
                          className=''
                          id='LaboratoryEmail'
                          name='LaboratoryEmail'
                          size='small'
                          onChange={handleChange}
                          required
                       />
                    </div>
                    <div className='col-4 mt-2'>
                    <TextField
                         inputProps={{ className: 'Registeration_Input' }}
                         InputLabelProps={{className:'AddPatient_Label' }}
                          label='Address'
                          className=''
                          id='LaboratoryAddress'
                          name='LaboratoryAddress'
                          size='small'
                          onChange={handleChange}
                          required
                       />
                    </div>
                    </div>
                </Box>
                <Box>
                    <div className='row'>
                        <h6>User Information</h6>
                    <div className='col-4 mt-2'>
                    <TextField
                          inputProps={{ className: 'Registeration_Input' }}
                          InputLabelProps={{className:'AddPatient_Label' }}
                          label='First Name'
                          className=''
                          id='FirstName'
                          name='FirstName'
                          size='small'
                          onChange={handleChange}
                          required
                       />
                    </div>
                    <div className='col-4 mt-2'>
                    <TextField
                         inputProps={{ className: 'Registeration_Input' }}
                         InputLabelProps={{className:'AddPatient_Label' }}
                          label='Last Name'
                          className=''
                          id='Lastname'
                          name='LastName'
                          size='small'
                          onChange={handleChange}
                          required
                       />
                    </div>
                    <div className='col-4 mt-2'>
                    <TextField
                         inputProps={{ className: 'Registeration_Input' }}
                         InputLabelProps={{className:'AddPatient_Label' }}
                          label='Email'
                          className=''
                          id='Email'
                          name='Email'
                          size='small'
                          onChange={handleChange}
                          required
                       />
                    </div>
                    <div className='col-4 mt-2'>
                      <TextField
                         inputProps={{ className: 'Registeration_Input' }}
                         InputLabelProps={{className:'AddPatient_Label' }}
                          label='Phone Number'
                          className=''
                          id='PhoneNumber'
                          name='PhoneNumber'
                          size='small'
                          onChange={handleChange}
                          required
                       />
                    
                    </div>
                    <div className='col-4 mt-2'>
                    <TextField
                         inputProps={{ className: 'Registeration_Input' }}
                         InputLabelProps={{className:'AddPatient_Label' }}
                          label='Password'
                          className=''
                          id='Password'
                          name='Password'
                          size='small'
                          type='Password'
                          onChange={handleChange}
                          required
                       />
                    </div>
                   
                    <div className='col-4 mt-2'>
                    <TextField
                         inputProps={{ className: 'Registeration_Input' }}
                         InputLabelProps={{className:'AddPatient_Label' }}
                          label='User Role'
                          className=''
                          id='user_Role'
                          name='user_Role'
                          size='small'
                          onChange={handleChange}
                          value={formData.user_Role}
                          select
                       >
                         {Role.map((option) => (
                           <MenuItem key={option.value} value={option.value}>
                              {option.label}
                           </MenuItem>
                           ))}
                        </TextField>
                    </div>
                    </div>
                </Box>

                   <div className='row pt-4'>
                    <div  className='col-3'>
                     <button type='submit' className="Submit_Button"  >save</button>
                     </div>
                   </div>
                </form>  
            </Card>    
         </div>
        </>
    )
}

export default  AccountRegistration