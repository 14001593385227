import React, { useEffect, useState } from 'react';
import '../UI/TestEntrymodal.css';
import { GetDataBySearch,Get_AgeGroup } from '../Services/TestServices';
import SpinnerModal from '../Spinner/SpinnerModal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useApi } from '../ContextApi/ContextApi';
import { Divider } from '@mui/material';
import { toast } from 'react-toastify';
import CancelIcon from '@mui/icons-material/Cancel';
import '../UI/LabOrder.css';

const LabOrder = React.memo(({onSubmit}) => {
  const [isLoading, setIsLoading] = useState(false);
  const[AgegroupData,SetAgegroupData] = useState([]);

  const [searchData, setSearchData] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [totalprice, setTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [DiscountAmmount ,setDiscountAmmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [ReceivedAmount, SetReceivedAmount] = useState(0);
  const [DueAmount, SetDueAmount] = useState(0);


  const [isReset, setIsReset] = useState(false);
  const [Params,setParams] = useState({
    SearchParam:'',
    GenderCategory:'',
    AGE_CODE:'',
    LabID:sessionStorage.getItem('LabID'), 
})

  const onChange = (e) => {
    const { name, value } = e.target;
    setParams({
      ...Params,
      [name]: value,
    });
   if(name == 'SearchParam' && value.length > 1){
    getTestsList(e);
   }
  };

  //Reset State after submitting form Successfully

  useEffect(() => {
    if (isReset) {
      setParams({ 
        SearchParams:'',
        GenderCategory:'',
        AGE_CODE:'',
        LabID:sessionStorage.getItem('LabID')
       });
      setSearchData([]);
      setSelectedTests([]);
      setTotalPrice(0);
      setDiscount(0);
      setGrandTotal(0);
      setIsReset(false);
    }
  }, [isReset]);
 
  // Search test 
  const getTestsList = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await GetDataBySearch(Params);
      setSearchData(response);
    } catch (error) {
      console.log('Error while searching test', error);
    } finally {
      setIsLoading(false);
    }
  };
   
  //Select and Add test
   const addToSelectedTests = (testItem) => {
    const isTestSelected = selectedTests.some((selectedTest) => selectedTest.testID === testItem.testID);
    if (isTestSelected) {
      const updatedTests = selectedTests.map((selectedTest) =>
        selectedTest.testID === testItem.testID ? { ...selectedTest, quantity: selectedTest.quantity + 1 } : selectedTest
      );
      setSelectedTests(updatedTests);
      toast.warning('Test Already selected')
      } else {
        setSelectedTests([...selectedTests, { ...testItem, quantity: 1 }]);
      }
     
    };
    //--------------------END-------------------
    //------------------Remove test from selected test ---------
    const removeTest = (id) => {
      const updatedList = selectedTests.filter((x) => x.testID !== id);
      setSelectedTests(updatedList);
    };
    //-----------End-----------
    //---------------------reset the list-------------
    const ResetSearchList = () =>{
      setSearchData([]);
    };
    //-----------END --------------------
 //-----------------------------price calculation--------------
   //Price Calculation 
   useEffect(() => {
    calculateTotalPrice();
  }, [selectedTests, discount]);

  const calculateTotalPrice = () => {
    const total = selectedTests.reduce((prev, current) => {
      const price = parseFloat(current.testPrice);
      return !isNaN(price) ? prev + price : prev;
    }, 0);
    setTotalPrice(total);
    const discountAmount = (total * discount) / 100;
    setGrandTotal(total - discountAmount);
    setDiscountAmmount(discountAmount);
  };

  const handleChangedDiscount = (e) => {
    const value = parseFloat(e.target.value);
    setDiscount(value);
  };
  
//---------------------End-----------------
  const formattedTests = selectedTests.map((testItem) => ({
    TestID: testItem.testID,
    LaboratoryID: sessionStorage.getItem('LabID'),
    Created_at: new Date(),
    CreatedBy: sessionStorage.getItem('UserID'),
    UpdatedBy: sessionStorage.getItem('UserID'),
    TotalBill: totalprice,
    Discount: DiscountAmmount,
    GrandTotal: grandTotal,
    ReceivedAmount:ReceivedAmount,
    DueAmount:DueAmount,
  }));

  //Call Call back function
  onSubmit(formattedTests)

  const Discount = [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 5,
      label: '5%',
    },
    {
      value: 10,
      label: '10%',
    },
    {
      value: 15,
      label: '15%',
    },
    {
      value: 20,
      label: '20%',
    },
    {
      value: 25,
      label: '25%',
    },
    {
      value: 30,
      label: '30%',
    },
    {
      value: 35,
      label: '35%',
    },
    {
      value: 40,
      label: '45%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 55,
      label: '55%',
    },
    {
      value: 60,
      label: '60%',
    },
    {
      value: 65,
      label: '65%',
    },
    {
      value: 70,
      label: '70%',
    },{
      value: 75,
      label: '75%',
    },
    {
      value: 80,
      label: '80%',
    },
    {
      value: 85,
      label: '85%',
    },
    {
      value: 90,
      label: '90%',
    },
    {
      value: 95,
      label: '95%',
    },
    {
      value: 100,
      label: '100%',
    },
   
  ];
  const Gender = [
    {
      value: '0',
      label: 'All',
    },
    {
      value: 'male',
      label: 'Male',
    },
    {
      value: 'female',
      label: 'Female',
    },
   
  ];
  //Server side search funciton -- temprory commenting 
// const OnSearchChange = (e) => {
//   const { value } = e.target;
//   const searchTerms = value.trim().toLowerCase().split(' ');
//   const filteredData = searchData.filter((testItem) => {
//       const testNameLower = testItem.testName.toLowerCase();
//       return searchTerms.every((term) => testNameLower.includes(term));
//   });
//   setSearchData(filteredData);
// };
//---------
const GetAgeGroup = async () => {
  try {
      const response = await Get_AgeGroup(sessionStorage.getItem('LabID'));
      SetAgegroupData(response);
  } catch (error) {
      console.error("Error fetching settings:", error);
  }
};
useEffect(()=>{
  GetAgeGroup();
},[])

//--------------select All test from search list------

const SelectAllTest = (e) => {
  e.preventDefault();
  if (searchData.length > 0) {
    const newSelectedTests = searchData.map(testItem => {
      const existingTest = selectedTests.find(selectedTest => selectedTest.testID === testItem.testID);
      if (existingTest) {
        return { ...existingTest, quantity: existingTest.quantity + 1 };
      } else {
        return { ...testItem, quantity: 1 };
      }
    });

    const updatedSelectedTests = [...selectedTests];

    newSelectedTests.forEach(newTest => {
      const index = updatedSelectedTests.findIndex(test => test.testID === newTest.testID);
      if (index !== -1) {
        updatedSelectedTests[index] = newTest;
      } else {
        updatedSelectedTests.push(newTest);
      }
    });
    setSelectedTests(updatedSelectedTests);
    toast.info('All tests have been added to the selected list');
  } else {
    toast.error('No tests available to select');
  }
};
//------------------End-----------------
//------------------Recevied and Due amount-------------
const onReceivedAmount = (e) =>{
  const value = e.target.value;
  SetReceivedAmount(value);
  const  dueamount  = grandTotal  - value;
  SetDueAmount(dueamount)
}
  return (
    <>
      {isLoading && <SpinnerModal isLoading={true} />}
      <div className="container-fluid pt-1 mt-3">
        <h5 className='common-FontWeight common-FontFamily'>Lab Order</h5>
        <form className="row justify-content-between">
          <div className="col-4">
            <div className='d-flex'> 
                  <div className='col-sm-6'>
                    <label className='common-Labels'>Geneder Groups</label>
                    <select
                        className="form-select   common-Input"
                        id='GenderCategory'
                        name='GenderCategory'
                        required
                        onChange={onChange}
                        value={Params.GenderCategory}
                        >
                        {Gender.map((op) => (
                            <option key={op.value} value={op.value}>
                            {op.label}
                            </option>
                        ))}
                     </select>
                    </div>
                    <div className='col-sm-6'>
                     <label className='common-Labels'>Age Groups</label>
                      <select className="form-select   common-Input"
                            id='AGE_CODE'
                            name='AGE_CODE'
                            required
                            onChange={onChange}
                            value={Params.AGE_CODE}
                            >
                           <option value='0'>All</option>
                           {AgegroupData.map((option) => (
                            <option key={option.agE_CODE} value={option.agE_CODE}>
                            {option.agE_VALUE}
                            </option>
                            ))}
                        </select>
                    </div>
            </div>  
            <div className='col-12 mt-2 common-FontFamily common-FontSize-small'>
                
                  <ul className="search_List">
                    <div className='Search-div form-control'>
                      <input 
                        name='SearchParam'
                        placeholder='Search Test' 
                        className='search-input common-Input' 
                        onChange={onChange}>
                      </input>
                      <button type='button' className='clear-button' onClick={ResetSearchList}>
                        <CancelIcon className='clear-cross'/>
                      </button>
                    </div>
                    <li>
                      <button onClick={SelectAllTest} className='select-All-search-list'>Select All</button>
                    </li>
                    {searchData && searchData.length > 0 ? searchData.map((testItem) => (
                      <li key={testItem.id} onClick={() => addToSelectedTests(testItem)}>
                        {testItem.testName}
                      </li>
                    )):<p style={{color:'red',textAlign:'center'}} className='common-FontFamily  common-FontSize-small'>No Record Found</p>}
                  </ul>
            </div>  
          </div>
          <div className="col-8" style={{ minHeight:'16rem',maxHeight:'17rem'}}>
              <div className="col-12 Test_table" style={{border:'1px solid lightgray'}}>
                <table className="w-100 Table">
                  <thead>
                    <tr>
                      <th>Test</th>
                      <th>Price</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedTests.map((testItem, index) => (
                      <tr key={testItem.testID}>
                        <td>{testItem.testName}</td>
                        <td>{testItem.testPrice}</td>
                        <td>
                          <CloseRoundedIcon className="Cross" onClick={() => removeTest(testItem.testID)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

            <Divider style={{background:'black'}} />
            <div className="row mt-3 common-FontSize-small common-FontFamily common-FontWeight">
              <div className="col-2">Total Price: {totalprice}</div>
              <div className="col-sm-3">
                  <label>Discount:</label>
                    <select className='DiscountDropdown'  name='Discount' onChange={handleChangedDiscount}>
                      {Discount.map((option) => (
                        <option  key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  
              </div>
               <div className="col-sm-2 d-flex">
                Grand Total: {grandTotal}
                </div>
                <div className="col-sm-3">
                    Recevied Ammount:
                    <input
                    className='ReceivedAmmount common-FontSize-small common-FontFamily common-FontWeight'
                    onChange={onReceivedAmount}   
                      value={ReceivedAmount}
                    >
                  </input>
                </div>
                <div className='col-sm-2'>
                   Due Amount:{DueAmount}
                </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
});

export default LabOrder;
