import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    
  } from '@mui/material';
  import { styled } from '@mui/material/styles';
  import  { tableCellClasses } from '@mui/material/TableCell';
import { useEffect,useState} from 'react';
import { toast } from 'react-toastify';
import { Monthly_Lab_Data } from '../../Services/Dashboard';
import SpinnerModal from '../../Spinner/SpinnerModal';

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5bc5d0',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: '#ffffff',
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));






const DateRangeReports = () =>{
    const [isLoading, setIsLoading] = useState(false);
    const[Monthly_Report,SetMonthly_Report] = useState([]);
    const [dataset, setDataset] = useState([]);
   //*************** States ********************* */
   const currentYear = new Date().getFullYear();
  const startDate = new Date(currentYear, 0, 1).toISOString().split('T')[0]; 
  const endDate = new Date(currentYear, 11, 31).toISOString().split('T')[0]; 

  const [formData, setFormData] = useState({
    StartDate: startDate,
    EndDate: endDate,
    LabID: sessionStorage.getItem('LabID')
  });
  const onchange = (e) =>{
    const {name,value} = e.target;
    setFormData({
        ...formData,
        [name]:value,
    }) 
}

const GetDashboardStates = async () =>{
  try{
    setIsLoading(true);
    const Monthly_Report = await Monthly_Lab_Data(formData);
      SetMonthly_Report(Monthly_Report)
      const newDataset = Monthly_Report.map(item => {
        return {
          month: item.monthName,
          total_monthly_Discount: item.totalDiscount,
          totalGrandTotal: item.totalGrandTotal
        };
      });
      setDataset(newDataset);
  }catch(error){
    toast.error("Unabled to get States data",error)
  }finally{
    setIsLoading(false);
  }
}
useEffect(() =>{
  GetDashboardStates();
},[])
const onApply = () =>{
  GetDashboardStates();
}
  //-------------------Pagination
 const [currentPage, setCurrentPage] = useState(0); // Start from page 1
 const itemsPerPage = 20;
 const indexOfLastItem = (currentPage + 1) * itemsPerPage;
 const indexOfFirstItem = indexOfLastItem - itemsPerPage;
 const limitedData = Monthly_Report.slice(indexOfFirstItem, indexOfLastItem);
 const handlePageChange = (event, newPage) => {
 setCurrentPage(newPage);
 };
    return(
        <>
         {isLoading && <SpinnerModal isLoading={isLoading} />}
          <div className="container-fluid">
              <div>
                <h3>Custome Date Range report</h3>
              </div>
               <div>
               <div className='row'>
                    <div className='col-sm mt-2'>
                          <label className='common-Labels' >Date from</label>
                            <input
                                  className="form-control common-Input"
                                  id='DateFrom'
                                  name='StartDate'
                                  required
                                  type='date'
                                  onChange={onchange}
                                  value={formData.StartDate}
                            />
                      </div>
                      <div className='col-sm mt-2'>
                      <label className='common-Labels' >Date To</label>
                        <input
                              className="form-control common-Input"
                              id='DateTo'
                              name='EndDate'
                              required
                              type='date'
                              onChange={onchange}
                              value={formData.EndDate}
                        />
                      </div>
                    <div className='col-sm-3 mt-3'>
                      <button type='button' style={{marginTop:'14px'}} onClick={onApply}  className="common-Apply" >Apply</button>
                    </div>
              
               </div>
               </div>
               <div style={{marginTop:'10px'}}>
               <TableContainer className='PatientListTable' sx={{ maxHeight: '80vh',minHeight:'80vh' }}>
                <Table  stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell className='PTC1'>Year</StyledTableCell>
                            <StyledTableCell className='PTC1'> Month</StyledTableCell>
                            <StyledTableCell className='PTC1'> sell</StyledTableCell>
                            <StyledTableCell className='PTC1'> Discount</StyledTableCell>
                            <StyledTableCell className='PTC1'> Recevied Ammount</StyledTableCell>
                            <StyledTableCell className='PTC1'>Due ammount</StyledTableCell>

                        </StyledTableRow>
                    </TableHead>
                    <TableBody>  
                    {limitedData.map((data, index) => (
                            <StyledTableRow  key={index}>
                             <StyledTableCell className='PTC common-FontWeight'>{data.year} </StyledTableCell>
                             <StyledTableCell className='PTC common-FontWeight'>{data.monthName}</StyledTableCell>
                             <StyledTableCell className='PTC common-FontWeight'>{data.totalGrandTotal}</StyledTableCell>
                             <StyledTableCell className='PTC common-FontWeight'>{data.totalDiscount}</StyledTableCell>
                             <StyledTableCell className='PTC common-FontWeight'>{data.receivedAmount}</StyledTableCell>
                             <StyledTableCell className='PTC common-FontWeight'>{data.dueAmount}</StyledTableCell>
                            </StyledTableRow>
                            ))}
                    </TableBody>
                  
                </Table>
                <TablePagination
                       component="div"
                       count={Monthly_Report.length}
                       page={currentPage}
                       onPageChange={handlePageChange}
                       rowsPerPage={itemsPerPage}
                       rowsPerPageOptions={[itemsPerPage]}
                   />
            </TableContainer>
               </div>
          </div>
        </>
    )
}

export default  DateRangeReports