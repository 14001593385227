import axios  from "axios";
// const baseURL = 'https://localhost:44397/api'; //local
const baseURL = 'https://securelab.api.sehhasolutions.com/api/'; //prod


const apiServices = axios.create({
    baseURL,
})

export  const AddLogoImage = async (formdata) =>{
    try{

        const token = sessionStorage.getItem('token');
        const response = await apiServices.post('/Home/UploadLogoImage', formdata,{
            headers: {
                'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
            }
        });
        return response.data;
    }
    catch(error){
        console.log("Add Test error",error)
        throw error
       }
}
export  const GetLogoImage = async (LabID) =>{
    try{
        const token = sessionStorage.getItem('token');
        const response = await apiServices.get(`/Home/GetLogoImage?LabID=${LabID}`,{
            headers: {
                'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
            }
        });
        return response.data;
    }
    catch(error){
        console.log("Add Test error",error)
        throw error
       }
}

export  const AddDrInfo = async (DrData) =>{
    try{
        const token = sessionStorage.getItem('token');
        const response = await apiServices.post('/Home/AddDoctorsInfo', 
            {
                "drname": DrData.drname,
                "designation": DrData.designation,
                "description": DrData.description,
                "address": DrData.address,
                "laboratoryid": DrData.laboratoryid  != null? DrData.laboratoryid:sessionStorage.getItem('LabID')
              }
              ,{
                headers: {
                    'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                }
            });
        return response.data;
    }
    catch(error){
        console.log("Add Test error",error)
        throw error
       }
}

export  const ADDTEMPLATESETTING = async (TEMPSET) =>{
    try{
        const token = sessionStorage.getItem('token');
        const response = await apiServices.post('/Home/AddTemplateSetting', 
            {
                "fontfamily": TEMPSET.fontfamily,
                "fontsize": TEMPSET.fontsize,
                "fontweight":'',
                "labid": TEMPSET.LabID  != null? TEMPSET.LabID:sessionStorage.getItem('LabID')
              }
              ,{
                headers: {
                    'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
                }
            });
        return response.data;
    }
    catch(error){
        console.log("Add font error",error)
        throw error
       }
}

export  const GetDocotrInfo = async (LabID) =>{
    try{
        const token = sessionStorage.getItem('token');
        const response = await apiServices.get(`/Home/GetDoctorInfo?LabID=${LabID}`,{
            headers: {
                'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
            }
        });
        return response.data;
    }
    catch(error){
        console.log("Get DR INfo error",error)
        throw error
       }
}

export  const DeleteDrInfo = async (LabiD,DRID) =>{
        
    try{
        const token = sessionStorage.getItem('token');
        const response  = await apiServices.delete(`/Home/DeleteDoctorInfo?LabiD=${LabiD}&DRID=${DRID}`,{
            headers: {
                'Authorization': `Bearer ${token}`,  // Attach the token to the Authorization header
            }
        });
        return response.data
    }
  
    catch(error){
        throw error
       }
}