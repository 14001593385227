import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
  } from '@mui/material';
  import { styled } from '@mui/material/styles';
  import  { tableCellClasses } from '@mui/material/TableCell';
import { useEffect,useState} from 'react';
import { toast } from 'react-toastify';
import { GetUser_ForDashboard } from '../../Services/Authservices';
import moment from 'moment/moment';
import SpinnerModal from '../../Spinner/SpinnerModal';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import Slide from '@mui/material/Slide';
import SignUp from '../SignUp';

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5bc5d0',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: '#ffffff',
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nStyledTableCell-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child StyledTableCell, &:last-child StyledTableCell': {
      border: 0,
    },
  }));

  const Transition = React.forwardRef(function Transition(props, ref) {
    const transitionDuration = 500; // milliseconds
    return <Slide direction="down" ref={ref} {...props} timeout={transitionDuration} />;
  });
  
  const UserLists = () =>{
    const[userDetails,SetuserDetails] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
      };
      const closeModal = () => {
        setModalIsOpen(false);
      };

    const GetUser  = async() =>{
        try{
          setIsLoading(true);
          const LabID = sessionStorage.getItem('LabID');
          const result  = await GetUser_ForDashboard(LabID)
          SetuserDetails(result)
        }catch(error){
         toast.error("Unabled to get user data",error)
        }finally{
            setIsLoading(false);
        }
       
      }

      useEffect(() => {
        GetUser();
      }, []);
    return(<>
          {isLoading && <SpinnerModal isLoading={true} />}
         <div  className="container-fluid">
         <table  className='table table-borderless'>
                    <thead>
                        <tr className='table-info'>
                            <td className='DTC1'>User Name</td>
                            <td className='DTC1'>Email</td>
                            <td className='DTC1'>Role</td>
                            <td className='DTC1'>
                              <button style={{border:'none',background:'transparent',outline:'none'}} onClick={openModal}><AddCircleRoundedIcon sx={{color:'#5bc5d0'}}/></button>
                            </td>
                        </tr>
                    </thead>
                    <tbody>  
                           {userDetails.map((data, index) => (
                            <tr  key={index}>
                             <td className='DTC'>{data.firstName} {data.lastName}</td>
                             <td className='DTC'>{data.email}</td>
                             <td className='DTC'>{data.user_Role}</td>
                             <td className='DTC'>
                                <EditRoundedIcon sx={{color:'#5bc5d0'}}/>
                             </td> 
                            </tr>
                            ))}
                    </tbody>
                </table>

         </div>
         <Dialog open={modalIsOpen} keepMounted onClose={closeModal}  TransitionComponent={Transition}>
                    <DialogTitle sx={{fontWeight:600,backgroundColor:'#5bc5d0',color:'white'}}>{"Add User"}</DialogTitle>
                    <Divider></Divider>
                    <DialogContent>
                        <SignUp />
                    </DialogContent>
                </Dialog>
      </>)
  }

  export default   UserLists