import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
  } from '@mui/material';
  import { styled } from '@mui/material/styles';
  import  { tableCellClasses } from '@mui/material/TableCell';
import { useEffect,useState} from 'react';
import { toast } from 'react-toastify';
import { MonthlyDailyReport } from '../../Services/Dashboard';
import SpinnerModal from '../../Spinner/SpinnerModal';

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5bc5d0',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: '#ffffff',
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));






const MonthlyReports = () =>{
    const [isLoading, setIsLoading] = useState(false);
    const[MonthlyReport,SetMonthlyReport] = useState([]);





    //     --------- Monthly daily report----------
const GetMonthlyDaily = async () =>{
    try{
      setIsLoading(true);
      const LabID = sessionStorage.getItem('LabID');
      const response = await MonthlyDailyReport(LabID);
       SetMonthlyReport(response.monthlyReports);
        
    }catch(error){
      toast.error("Unabled to get States data",error)
    }finally{
      setIsLoading(false);
    }
  }

  useEffect(()=>{
    GetMonthlyDaily();
  },[])

  //-------------------Pagination
 const [currentPage, setCurrentPage] = useState(0); // Start from page 1
 const itemsPerPage = 20;
 const indexOfLastItem = (currentPage + 1) * itemsPerPage;
 const indexOfFirstItem = indexOfLastItem - itemsPerPage;
 const limitedData = MonthlyReport.slice(indexOfFirstItem, indexOfLastItem);
 const handlePageChange = (event, newPage) => {
 setCurrentPage(newPage);
 };
    return(
        <>
         {isLoading && <SpinnerModal isLoading={isLoading} />}
          <div className="container-fluid">
              <div>
                <h3>Monthly report</h3>
              </div>
              
               <div>
               <TableContainer className='PatientListTable' sx={{ maxHeight: '80vh',minHeight:'80vh' }}>
                <Table  stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell className='PTC1'>Month</StyledTableCell>
                            <StyledTableCell className='PTC1'> Tests</StyledTableCell>
                            <StyledTableCell className='PTC1'> sell</StyledTableCell>
                            <StyledTableCell className='PTC1'> Discount</StyledTableCell>
                            <StyledTableCell className='PTC1'> Recevied Ammount</StyledTableCell>
                            <StyledTableCell className='PTC1'>Due ammount</StyledTableCell>

                        </StyledTableRow>
                    </TableHead>
                    <TableBody>  
                    {limitedData.map((data, index) => (
                            <StyledTableRow  key={index}>
                             <StyledTableCell className='PTC common-FontWeight'>{data.monthName} </StyledTableCell>
                             <StyledTableCell className='PTC common-FontWeight'>{data.totalTest}</StyledTableCell>
                             <StyledTableCell className='PTC common-FontWeight'>{data.totalGrandTotal}</StyledTableCell>
                             <StyledTableCell className='PTC common-FontWeight'>{data.totalDiscount}</StyledTableCell>
                             <StyledTableCell className='PTC common-FontWeight'>{data.receivedAmount}</StyledTableCell>
                             <StyledTableCell className='PTC common-FontWeight'>{data.dueAmount}</StyledTableCell>
                            </StyledTableRow>
                            ))}
                    </TableBody>
                  
                </Table>
                <hr></hr>
                <TablePagination
                       component="div"
                       count={MonthlyReport.length}
                       page={currentPage}
                       onPageChange={handlePageChange}
                       rowsPerPage={itemsPerPage}
                       rowsPerPageOptions={[itemsPerPage]}
                   />
            </TableContainer>
               </div>
          </div>
        </>
    )
}

export default  MonthlyReports