import React from 'react'
import  {useEffect, useState} from 'react'
import { AddTest,GetSingleTest,Get_Categorry,Get_AgeGroup,Get_SubCategory } from '../Services/TestServices';
import SpinnerModal from '../Spinner/SpinnerModal';
import { useApi } from '../ContextApi/ContextApi';
import { toast } from 'react-toastify';
import '../UI/TestEntrymodal.css'
import '../UI/Common.css'
const TestRegistration = ({TestID}) =>{
    const { setTest_SubmissionStatus } = useApi();
    const [isLoading, setIsLoading] = useState(false);  
    const [TestData,setTestData] = useState({});
  const[CategorryData,SetCateGorryData] = useState([]);
  const[AgegroupData,SetAgegroupData] = useState([]);
  const[SubCategory,SetSubCategory] = useState([]);
    const[formData,setformData] = useState({
        TestID:'',
        test_name:'',
        TestCategoryName:'',
        TestPrice :'',
        Unit:'',
        Reference_value:'',
        Comments:'',
        CATEGORY_CODE:'',
        GenderCategory:'',
        AGE_CODE:'',
        SUB_CATEGORY_CODE:''
    });
    const onchange = (e) =>{
        const {name,value} = e.target;
        setformData({
            ...formData,
            [name]:value,
        }) 
    }
    //Submit form 
    const SubmitForm = async(e) =>{
        e.preventDefault();
        setIsLoading(true)
        try{
            const response = await  AddTest(formData);
            if(response){
                toast.success("test added success fully")
                setTest_SubmissionStatus(true)
            }
        }
        catch(error){
            throw(error)
        }
        finally{
            setIsLoading(false)
        }
     
    }
    //Getting Test Data for Editng Purpose
        const RetriveTestData = async ()=>{
            if(TestID){
                setIsLoading(true)
                const LabID = sessionStorage.getItem('LabID');
                const Response = await GetSingleTest(TestID,LabID);
                setTestData(Response)
                if(Response){
                    setIsLoading(false);
                }
            }
        }

        const GetSettingData = async () => {
            try {
                const response = await Get_Categorry(sessionStorage.getItem('LabID'));
                SetCateGorryData(response);
            } catch (error) {
                console.error("Error fetching settings:", error);
            }
        };
        const GetAgeGroup = async () => {
            try {
                const response = await Get_AgeGroup(sessionStorage.getItem('LabID'));
                SetAgegroupData(response);
            } catch (error) {
                console.error("Error fetching settings:", error);
            }
          };
          const GetSubCategory = async () => {
            try {
                
                const response = await Get_SubCategory(sessionStorage.getItem('LabID'));
                SetSubCategory(response);
            } catch (error) {
                console.error("Error fetching settings:", error);
            }
          };
        useEffect(() => {
             RetriveTestData();
             GetAgeGroup();
             GetSubCategory();
        },[TestID])

         useEffect(()=>{
            GetSettingData();
         },[])
   //mapped testData for edit 
   useEffect(() => {
    setformData({
        TestID:TestID,
        test_name: TestData.testName || '',
        TestPrice: TestData.testPrice || '',
        Unit: TestData.unit || '',
        Reference_value: TestData.normalRange || '',
        Comments: TestData.comments || '',
        CATEGORY_CODE:TestData.categorY_CODE || '',
        GenderCategory:TestData.genderCategory || '',
        AGE_CODE:TestData.agE_CODE || '',
        SUB_CATEGORY_CODE:TestData.suB_CATEGORY_CODE || ''
    });
}, [TestData]); 
//-----main Domain-------
const Gender = [
    {
      value: '0',
      label: 'All',
    },
    {
      value: 'male',
      label: 'Male',
    },
    {
      value: 'female',
      label: 'Female',
    },
   
  ];
return (
        <>
     {isLoading && <SpinnerModal isLoading={isLoading} />}
        <div className='container-fluid pt-1'>
            <form onSubmit={SubmitForm}>
                <div className='row pt-1'>
                    <div className='col-sm mt-2'>
                    <label className='common-Labels' >Main Catagory <span className='common-Asterisk'>*</span></label>
                      <select
                            className="form-select  common-Input"
                            id='CATEGORY_CODE'
                            name='CATEGORY_CODE'
                            required
                            onChange={onchange}
                            value={formData.CATEGORY_CODE}
                            >
                            <option>Select options</option>
                            {CategorryData.map((option) => (
                            <option key={option.categorY_CODE} value={option.categorY_CODE}>
                            {option.categorY_VALUE}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-sm mt-2'>
                    <label className='common-Labels' >Sub Catagory <span className='common-Asterisk'>*</span></label>
                      <select
                            className="form-select common-Input"
                            id='SUB_CATEGORY_CODE'
                            name='SUB_CATEGORY_CODE'
                            required
                            onChange={onchange}
                            value={formData.SUB_CATEGORY_CODE}
                            >
                            <option>Select options</option>
                            {SubCategory.map((option) => (
                            <option key={option.suB_CATEGORY_CODE} value={option.suB_CATEGORY_CODE}>
                            {option.suB_CATEGORY_VALUE}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-3 mt-2'>
                    <label className='common-Labels' >Geneder Groups <span className='common-Asterisk'>*</span></label>
                    <select
                        className="form-select  common-Input"
                        id='GenderCategory'
                        name='GenderCategory'
                        required
                        onChange={onchange}
                        value={formData.GenderCategory}
                        >
                        {Gender.map((option) => (
                            <option key={option.value} value={option.value}>
                            {option.label}
                            </option>
                        ))}
                    </select>
                    </div>
                    <div className='col-3 mt-2'>
                     <label className='common-Labels'>Age Groups <span className='common-Asterisk'>*</span></label>
                      <select className="form-select  common-Input"
                            id='AGE_CODE'
                            name='AGE_CODE'
                            required
                            onChange={onchange}
                            value={formData.AGE_CODE}
                            >
                            <option value='0'>All</option>    
                            {AgegroupData.map((option) => (
                            <option key={option.agE_CODE} value={option.agE_CODE}>
                            {option.agE_VALUE}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div  className='col-3 mt-2'>
                        <label className='common-Labels' >Test Name <span className='common-Asterisk'>*</span></label>
                         <input  
                            className="form-control  common-Input" 
                            id='test_name'
                            name='test_name'
                            required 
                            value={formData.test_name}
                            onChange={onchange}
                            >
                        </input>
                    </div>
                    <div  className='col-3 mt-2'>
                     <label className='common-Labels'>Test Price <span className='common-Asterisk'>*</span></label>
                        <input
                            className="form-control form-control common-Input"
                            id='TestPrice'
                            name='TestPrice'
                            value={formData.TestPrice}
                            required
                            onChange={onchange} />
                    </div>
                    <div  className='col-3 mt-2'>
                    <label className='common-Labels' >Refference Value <span className='common-Asterisk'>*</span></label>
                        <textarea
                            label='Reference Value'
                            className="form-control common-Textarea"
                            id='Reference_value'
                            name='Reference_value'
                            required
                            value={formData.Reference_value}
                            onChange={onchange} />
                    </div>
                      <div  className='col-3 mt-2'>
                      <label className='common-Labels' >Unit <span className='common-Asterisk'>*</span></label>
                          <textarea
                              label='Unit'
                             className="form-control common-Textarea"
                              id='Unit'
                              name='Unit'
                              value={formData.Unit}
                               required
                               onChange={onchange} />
                        </div>
                        <div  className='col-3 mt-2'>
                        <label className='common-Labels'>Comment </label>
                          <input
                              label='Comments'
                              className="form-control common-Input"
                              id='Comments'
                              name='Comments'
                              value={formData.Comments}
                              onChange={onchange} />
                        </div>
                         <div  className='col-3 mt-2'>
                          <button className="btn common-button">Save</button>
                         </div>
                    </div>
            </form>
        </div>
        </>
    
    )
}

export default TestRegistration